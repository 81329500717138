/*
Default Style
============================*/
$body-font-family: 'Open Sans', sans-serif;
$heading-font-family: 'Dosis', sans-serif;
$main-color: #00a7f5; 
$body-color: #4d4d4d;
$heading-color: #22105f;
$white-color: #ffffff;
$black-color: #000000;
$box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
$transition: all 0.5s;
$border-radius: 5px;

body {
    margin: 0;
    padding: 0;
    line-height: 1.8;
	color: $body-color;
	font: {
		family: $body-font-family;
		size: 15px;
	};
}
a {
	transition: $transition;
	color: $heading-color;
	text-decoration: none;
	
	&:hover {
        text-decoration: none;
        color: $main-color;
	}
}
:focus {
	outline: 0 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
	font: {
		family: $heading-font-family;
		weight: 700;
	};
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: 15px;
	margin-bottom: 15px;
	line-height: 1.8;

	&:last-child {
        margin-bottom: 0;
    }
} 
img {
    max-width: 100%;
    height: auto;
}
.form-control {
    height: 50px;
    color: $heading-color;
	border: 1px solid #e8e8e8;
	background-color: #f7f7f7;
	border-radius: 0;
	padding: 0;
	font-size: 15px;
	padding: {
		top: 0;
		bottom: 0;
		left: 15px;
		right: 15px;
	};
	&::placeholder {
		color: #495057;
	}
	&:focus {
		color: $black-color;
		background-color: transparent;
		box-shadow: unset;
		outline: 0;
		border: 1px solid $main-color;
	}
}
.form-control:hover, .form-control {
    &:focus {
        box-shadow: unset;
    }
}
textarea {
    &.form-control {
		padding-top: 15px;
		height: 250px;
    }
}
.ptb-100 {
	padding: {
		top: 100px;
		bottom: 100px;
	};
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mb-30 {
	margin-bottom: 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pr-30 {
	padding-right: 30px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-20 {
	margin-top: 20px;
}
.mtb-100 {
	margin: {
		top: 100px;
		bottom: 100px;
	};
}
.mb-20 {
	margin-bottom: 20px;
}
.mt-minus-70 {
	margin-top: -70px;
}
.mb-minus-70 {
	margin-bottom: -70px;
}
.ml-30 {
	margin-left: 30px;
}
.mr-30 {
	margin-right: 30px;
}
.fun-blue-bg {
	background-color: #24416b;
}