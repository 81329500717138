
@import "./app/theme/_theme.scss";

/*
Go Top Style
======================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
	top: 0;
	right: 15px;
	background-color: $heading-color;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: .9s;
	transition: .9s;

	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		color: $white-color;
		transition: 0.5s;
		font-size: 20px;

		&:last-child {
			opacity: 0;
			visibility: hidden;
			top: 60%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $main-color;
		opacity: 0;
		visibility: hidden;
		transition: 0.5s;
	}
	&:hover {
		color: $white-color;
		background-color: $main-color;

		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
	&:focus {
		color: $white-color;

		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
}
.go-top.active {
	top: 98%;
	transform: translateY(-98%);
	opacity: 1;
	visibility: visible;
}

/*
Page-navigation Area Style
======================================================*/
.page-navigation-area {
	text-align: center;
	margin: 30px auto 0;
	display: table;

	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;
	}
	.page-link {
		color: $main-color;
		background-color: $white-color;
		box-shadow: $box-shadow;
		width: 40px;
		height: 40px;
		border: 1px solid $white-color;
		line-height: 24px;
		transition: $transition;
		font-weight: 700;

		i {
			margin-right: -4px;
			font-size: 21px;
		}
		&:hover {
			color: $white-color;
			background-color: $main-color;
			border: 1px solid $main-color;
		}
		&:focus {
			z-index: 2;
			outline: 0;
			box-shadow: none;
		}
	}
	.page-link.page-links {
		i {
			&::before {
				margin-left: -4px;
			}
		}
	}
	.page-item {
		padding: 0 8px 0;

		&:first-child {
			.page-link {
				border-radius: none;
			}
		}
		&:last-child {
			.page-link {
				border-radius: none;
			}
		}
	}
	.page-item.active {
		.page-link {
			z-index: 1;
			color: $white-color;
			background-color: $main-color;
			border-color: $main-color;
		}
	}
}

/*
Video wave Style
======================================================*/
.video-btn {
	display: inline-block;
	width: 60px;
	height: 60px;
	line-height: 69px;
	text-align: center;
	border-radius: 50%;
	color: $main-color;
	position: relative;
	top: 3px;
	z-index: 1;
	background-color: $white-color;

	i {
		font-size: 30px;
		font-weight: 700;
		padding-left: 4px;
		color: $main-color;
		position: relative;
		top: 5px;
		left: 0;
	}
	&::after, &::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		background-color: $white-color;
	}
	&::before {
		animation: ripple 1.6s ease-out infinite;
	}
	&::after {
		animation: ripple 1.6s ease-out infinite;
	}
	&:hover {
		background-color: $main-color;

		i {
			color: $white-color;
		}
	}
}
@keyframes ripple {
	0%, 35% {
		transform: scale(0);
	  opacity: 1;
	}
	50% {
		transform: scale(1.5);
	  	opacity: 0.8;
	}
	100% {
	  	opacity: 0;
		transform: scale(2);
	}
}

/*
Default Btn Area Style
======================================================*/
.default-btn {
	color: $white-color;
	border: none;
	box-shadow: unset !important;
	padding: 11px 35px;
	transition: $transition;
	z-index: 1;
	text-transform: capitalize;
	border-radius: 30px;
	background: $main-color;
	display: inline-block;
	cursor: pointer;
	position: relative;
	font: {
		weight: 700;
		size: 15px;
	};
	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 30px;
		z-index: -1;
		background: linear-gradient(to bottom right, #ff416c, #fa4612);
		transition: $transition;
	}
	&:hover {
		color: $white-color;

		&::before {
			opacity: 0;
			visibility: hidden;
		}
	}
}

/*
Read More Area Style
======================================================*/
.read-more {
	color: $heading-color;
	transition: $transition;
	display: inline-block;
	font: {
		size: 14px;
		weight: 600;
	};
	i {
		position: relative;
		top: 4px;
		font-size: 20px;
		margin-left: -4px;
	}
	&:hover {
		color: $main-color;
	}
}

/*
Section Title Area Style
======================================================*/
.section-title {
	max-width: 625px;
	margin: -9px auto 55px;
	text-align: center;

	h2 {
		margin-bottom: 14px;
		font: {
			size: 40px;
		};
	}
}

/*
Preloader Area Style
======================================================*/
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: $main-color;
	top: 0;
	left: 0;
	z-index: 99999;

	.loader {
		position: absolute;
		left: 50%;
		top: 48%;
		transform: translateY(-48%) translateX(-50%);

		.sbl-puzzle {
			height: 60px;
			width: 60px;
			color: $white-color;
			position: relative;
			display: inline-block;
	
			div {
				border-radius: 50%;
				position: absolute;
				margin: auto;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 3px solid;
				animation: rotateTest1 4s linear infinite;
				box-sizing: border-box;
				border: {
					top-color: transparent;
					bottom-color: transparent;
				};
				&:nth-child(1) {
					height: 20%;
					width: 20%;
					animation-delay: 0.5s;
				}
				&:nth-child(2) {
					height: 40%;
					width: 40%;
					animation-delay: 1s;
				}
				&:nth-child(3) {
					height: 60%;
					width: 60%;
					animation-delay: 1.5s;
				}
				&:nth-child(4) {
					height: 80%;
					width: 80%;
					animation-delay: 2s;
				}
				&:nth-child(5) {
					height: 100%;
					width: 100%;
					animation-delay: 2.5s;
				}
			}
		}
	}
}
@keyframes rotateTest1 {
	50% {
		transform: rotate(360deg);
	}
}

/*
Keyframes Area Style
======================================================*/
@keyframes moveLeftBounces {
	0% {
		transform: translateX(0);
	}
	50% {
	
		transform: translateX(45px);
	}
	100% {
		transform: translateX(0);
	}
} 
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
} 
@keyframes movebounce {
	0% {
	  	transform: translateY(0px);
	}
	50% {
	  	transform: translateY(20px);
	}
	100% {
	  	transform: translateY(0px);
	}
}
@keyframes moVEbounce {
	0% {
	  	transform: translateY(0px);
	}
	50% {
	  	transform: translateY(100px);
	}
	100% {
	  	transform: translateY(0px);
	}
}
@keyframes moVebounce {
	0% {
	  	transform: translateY(0px);
	}
	50% {
	  	transform: translateY(50px);
	}
	100% {
	  	transform: translateY(0px);
	}
}
@keyframes Movebounce {
	0% {
	  transform: translateY(0px) rotate(-90deg);
	}
	50% {
	  transform: translateY(20px) rotate(-90deg);
	}
	100% {
	  transform: translateY(0px) rotate(-90deg);
	}
}
@keyframes animationFramesOne {
	0% {
	  	transform: translate(0px, 0px) rotate(0deg);
	}
	80% {
	  	transform: translate(-300px, 0px) rotate(360deg);
	}
	100% {
	  	transform: translate(0px, 0px) rotate(0deg);
	}
}
@keyframes focus-one {
	0% {
	  opacity: 0;
	}
	75% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
}

/*
Nice Select Area Style
======================================================*/
.nice-select {
	.option {
		display: block;

		&:hover {
			background-color: $main-color;
			color: $white-color;
		}
	}
	.option.selected.focus {
		background-color: $heading-color;
		color: $main-color;
	}
	.list {
		width: 100%;
	}
}
/*===== DEFAULT STYLE END ======*/

/*
Navbar Area Style
======================================================*/ 
.navbar {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 999;
	height: auto;
	padding: {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	};
	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;
	}
	.navbar-nav {
		margin: {
			left: auto;
			right: auto;
		};
		.nav-item2 {
			display: none;
		}
		.nav-item {
			position: relative;
			margin: {
				left: 14px;
				right: 14px;
			};
			.nav-link {
				color: $white-color;
				text-transform: capitalize;
				transition: $transition;
				padding: 0;
				font: {
					weight: 600;
					size: 16px;
				};
				margin: {
					left: 0;
					right: 0;
				};
				padding: {
					top: 28px;
					bottom: 30px;
				};
				i {
					font-size: 20px;
					line-height: 1;
					position: relative;
					margin-left: -3px;
					display: inline-block;
					top: 4px;
				}
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			.dropdown-menu {
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
				background: $white-color;
				position: absolute;
				border: none;
				top: 70px;
				left: 0;
				width: 250px;
				z-index: 99;
				display: block;
				opacity: 0;
				visibility: hidden;
				border-radius: 0;
				margin: 0;
				transition: all 0.2s ease-in-out;
				padding: 0;
				transform: scaleX(0);
				padding: {
					top: 10px;
					bottom: 10px;
				};
				li {
					margin: 0;

					a {
						padding: 7px 20px;
						margin: 0;
						color: $black-color;
						font: {
							size: 15px;
							weight: 600;
						};
						i {
							margin: 0;
							position: absolute;
							top: 50%;
							font-size: 20px;
							transform: translateY(-50%);
							right: 10px;
						}
						&:hover, &:focus, &.active {
							color: $main-color;
						}
					}
					.dropdown-menu {
						left: -250px;
						top: 0;
						opacity: 0;
						visibility: hidden;
						transform: scaleY(0);
						
						li {
							a {
								color: $black-color;
								
								&:hover, &:focus, &.active {
									color: $main-color;
								}
							}
							.dropdown-menu {
								left: 250px;
								top: 0;
								opacity: 0;
								visibility: hidden;
								transform: scaleY(0);
	
								li {
									a {
										color: $black-color;
	
										&:hover, &:focus, &.active {
											color: $main-color;
										}
									}
									.dropdown-menu {
										left: -250px;
										top: 0;
										opacity: 0;
										visibility: hidden;
										transform: scaleY(0);
			
										li {
											a {
												color: $black-color;
			
												&:hover, &:focus, &.active {
													color: $main-color;
												}
											}
											.dropdown-menu {
												left: 250px;
												top: 0;
												opacity: 0;
												visibility: hidden;
												transform: scaleY(0);
					
												li {
													a {
														color: $black-color;
			
														&:hover, &:focus, &.active {
															color: $main-color;
														}
													}
													.dropdown-menu {
														left: -250px;
														top: 0;
														opacity: 0;
														visibility: hidden;
														transform: scaleY(0);
							
														li {
															a {
																color: $black-color;
			
																&:hover, &:focus, &.active {
																	color: $main-color;
																}
															}
															.dropdown-menu {
																left: 250px;
																top: 0;
																opacity: 0;
																visibility: hidden;
																transform: scaleY(0);
									
																li {
																	a {
																		color: $black-color;
			
																		&:hover, &:focus, &.active {
																			color: $main-color;
																		}
																	}
																}
															}
															&.active {
																a {
																	color: $main-color;
																}
															}
															&:hover {
																.dropdown-menu {
																	opacity: 1;
																	visibility: visible;
																	transform: scaleY(1);
																}
															}
														}
													}
													&.active {
														a {
															color: $main-color;
														}
													}
													&:hover {
														.dropdown-menu {
															opacity: 1;
															visibility: visible;
															transform: scaleY(1);
														}
													}
												}
											}
											&.active {
												a {
													color: $main-color;
												}
											}
											&:hover {
												.dropdown-menu {
													opacity: 1;
													visibility: visible;
													transform: scaleY(1);
												}
											}
										}
									}
									&.active {
										a {
											color: $main-color;
										}
									}
									&:hover {
										.dropdown-menu {
											opacity: 1;
											visibility: visible;
											transform: scaleY(1);
										}
									}
								}
							}
							&.active {
								a {
									color: $main-color;
								}
							}
							&:hover {
								.dropdown-menu {
									opacity: 1;
									visibility: visible;
									transform: scaleY(1);
								}
							}
						}
					}
					&.active {
						a {
							color: $main-color;
						}
					}
					&:hover {
						.dropdown-menu {
							opacity: 1;
							visibility: visible;
							transform: scaleY(1);
						}
					}
				}
			}
			&:hover {
				.dropdown-menu {
					opacity: 1;
					visibility: visible;
					transform: scaleX(1);
				}
			}

		}
	}
	&.is-sticky {
		opacity: 1;
		visibility: visible;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		background: $main-color !important;
		animation: .5s ease-in-out 0s normal none 1 running fadeInDown;

		.others-option {
			.default-btn {
				background-color: $white-color;

				&:hover {
					color: $heading-color;
				}
			}
		}
	}
}
.navbar-light {
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	}
}

// Mobile and iPad Navbar
@media only screen and (max-width: 767px) {

	.navbar-light {
        .navbar-toggler {
            border-radius: 0;
            border-color: $white-color;
        }
    }
    .navbar {
        padding: {
            top: 15px;
            bottom: 15px;
        };
        .container {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
        .navbar-nav {
            background-color: $white-color;
            margin-left: 0;
			margin-right: 0;
			margin-top: 15px;
			height: 400px;
			overflow-y: scroll;
			padding: {
				left: 15px;
				right: 15px;
				top: 5px;
				bottom: 5px;
			};
			.nav-item {
				margin-left: 0;
				margin-right: 0;

				.nav-link {
					color: $heading-color;
					padding: {
						top: 8px;
						bottom: 8px;
					};
				}
				.dropdown-menu {
					box-shadow: unset;
					position: relative;
					top: 0 !important;
					left: 0;
					width: 100%;
					z-index: 99;
					opacity: 1;
					margin-top: 0 !important;
					visibility: visible;
					transform: scaleX(1);
					padding-top: 10px;
					padding-bottom: 10px;
					border: 1px solid #eeeeee;

					li {
						a {
							i {
								margin: 0;
								position: absolute;
								top: 10px;
								font-size: 20px;
								transform: translateY(0);
								right: 10px;
							}
						}
						.dropdown-menu {
							opacity: 1;
							visibility: visible;
							transform: scaleY(1);
							left: 0;
							width: 92%;
						}
					}
				}
			}
        }
        .collapse:not(.show).active {
            display: block;
        }
    }

}

/*
Main Banner Area Style
======================================================*/ 
.main-banner-area {
	background-color: #04063c;
	height: 850px;
	position: relative;
	overflow: hidden;
	z-index: 1;

	.banner-text {
		max-width: 630px;
		margin-left: auto;

		h1 {
			color: $white-color;
			font-size: 50px;
			margin-bottom: 20px;
		}
		p {
			color: $white-color;
			margin-bottom: 40px;
			text-transform: capitalize;
		}
		.banner-btn {
			.default-btn {
				margin-right: 15px;
				background-color: $white-color;

				&.active {
					margin-right: 0;
					color: $heading-color;
					background-color: #ff4b2b;

					&::before {
						background: $white-color;
					}
					&:hover {
						color: $white-color;
					}
				}
				&:hover {
					color: $heading-color;
				}
			}
		}
	}
	.banner-main-img {
		display: none;
	}
	.white-shape {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	.shape-line-one {
		img {
			position: absolute;
			z-index: -1;

			&:nth-child(1) {
				top: -50px;
				left: 30px;
				animation: moVebounce 5s linear infinite;
			}
			&:nth-child(2) {
				top: -50px;
				left: 100px;
				animation: moVebounce 6s linear infinite;
			}
			&:nth-child(3) {
				top: -50px;
				left: 200px;
				animation: moVebounce 7s linear infinite;
			}
			&:nth-child(4) {
				top: -50px;
				left: 300px;
				animation: moVebounce 8s linear infinite;
			}
			&:nth-child(5) {
				top: -50px;
				left: 400px;
				animation: moVebounce 9s linear infinite;
			}
			&:nth-child(6) {
				top: -50px;
				left: 500px;
				animation: moVebounce 10s linear infinite;
			}
			&:nth-child(7) {
				top: -50px;
				left: 600px;
				animation: moVebounce 11s linear infinite;
			}
			&:nth-child(8) {
				top: -50px;
				left: 700px;
				animation: moVebounce 12s linear infinite;
			}
			&:nth-child(9) {
				top: -50px;
				left: 800px;
				animation: moVebounce 13s linear infinite;
			}
			&:nth-child(10) {
				top: -50px;
				left: 900px;
				animation: moVebounce 14s linear infinite;
			}
			&:nth-child(11) {
				top: -50px;
				left: 1000px;
				animation: moVebounce 15s linear infinite;
			}
			&:nth-child(12) {
				top: -50px;
				left: 1100px;
				animation: moVebounce 16s linear infinite;
			}
			&:nth-child(13) {
				top: -50px;
				left: 1200px;
				animation: moVebounce 17s linear infinite;
			}
			&:nth-child(14) {
				top: -50px;
				left: 1300px;
				animation: moVebounce 18s linear infinite;
			}
			&:nth-child(15) {
				top: -50px;
				left: 1400px;
				animation: moVebounce 19s linear infinite;
			}
			&:nth-child(16) {
				top: -50px;
				left: 1500px;
				animation: moVebounce 20s linear infinite;
			}
			&:nth-child(17) {
				top: -50px;
				left: 1600px;
				animation: moVebounce 21s linear infinite;
			}
			&:nth-child(18) {
				top: -50px;
				left: 1700px;
				animation: moVebounce 16s linear infinite;
			}
			&:nth-child(19) {
				top: -50px;
				left: 1800px;
				animation: moVebounce 15s linear infinite;
			}
			&:nth-child(20) {
				top: -50px;
				left: 1900px;
				animation: moVebounce 14s linear infinite;
			}
			&:nth-child(21) {
				top: -50px;
				left: 2000px;
				animation: moVebounce 13s linear infinite;
			}
			&:nth-child(22) {
				top: -50px;
				left: 2100px;
				animation: moVebounce 12s linear infinite;
			}
			&:nth-child(23) {
				top: -50px;
				left: 2200px;
				animation: moVebounce 11s linear infinite;
			}
			&:nth-child(24) {
				top: -50px;
				left: 2300px;
				animation: moVebounce 10s linear infinite;
			}
			&:nth-child(25) {
				top: -50px;
				left: 2400px;
				animation: moVebounce 9s linear infinite;
			}
			&:nth-child(26) {
				top: 50px;
				left: 0;
				animation: moVebounce 8s linear infinite;
				animation: focus-one 1s linear 1s infinite;
			}
			&:nth-child(27) {
				bottom: 0;
				left: 0;
				animation: moVebounce 7s linear infinite;
				animation: focus-one 2s linear 2s infinite;
			}
			&:nth-child(28) {
				top: 50px;
				right: 0;
				animation: moVebounce 6s linear infinite;
				animation: focus-one 3s linear 3s infinite;
			}
			&:nth-child(29) {
				bottom: 0;
				right: 0;
				animation: moVebounce 5s linear infinite;
				animation: focus-one 4s linear 4s infinite;
			}
		}
	}
	.col-lg-6 {
		position: relative;
	}
	&.main-banner-area-one {
		.banner-text {
			margin-top: -50px;
		}
		.banner-img {
			position: relative;
			z-index: 1;
			top: -50px;

			img{
				position: absolute;

				&:nth-child(1) {
					top: 0;
					right: 0;
					left: 0;
					margin: auto;
				}
				&:nth-child(2) {
					top: -45px;
					right: 0;
					left: 0;
					margin: auto;
				}
				&:nth-child(3) {
					top: -100px;
					left: -20px;
					right: 0;
					margin: auto;
					animation: moVebounce 5s linear infinite;
				}
				&:nth-child(4) {
					top: -85px;
					left: -15px;
					right: 0;
					margin: auto;
				}
				&:nth-child(5) {
					top: 50px;
    				right: 0;
				}
				&:nth-child(6) {
					top: 210px;
					right: 75px;
				}
				&:nth-child(7) {
					top: 200px;
					left: 45px;
				}
				&:nth-child(8) {
					top: 90px;
					left: -10px;
				}
			}
		}
	}
} 

/*
Search Domain Area Style
======================================================*/
.search-domain {
	position: relative;

	.domain-wrap {
		position: relative;

		.input-domain {
			height: 70px;
			padding: 10px 20px;
			width: 100%;
			border-radius: 50px;
			border: none;
			display: block;

			&:focus {
				outline: 0;
			}
		}
		.default-btn {
			position: absolute;
			top: 10px;
			right: 10px;
		}
		.select-domain-name {
			position: absolute;
			top: 16px;
			right: 140px;
			border: none;
		}
	}
}

/*
Technology Area Style
======================================================*/
.single-technology {
	text-align: center;
	padding: 30px;
	box-shadow: $box-shadow;
	background-color: $white-color;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: $transition;
	margin-bottom: 30px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 350px;
		width: 100%;
		height: 100%;
		opacity: 0.10;
		z-index: -1;
		transition: all 1s;
		background: {
			image: url(assets/img/shape/card-bg.png);
			position: center center;
			size: cover;
			repeat: no-repeat;
		};
	}
	.technology-icon-bg {
		position: relative;
		display: inline-block;
		margin-bottom: 30px;

		i {
			position: absolute;
			top: 0;
			left: 0;
			justify-content: center;
			align-items: center;
			display: flex;
			width: 100%;
			height: 100%;
			font-size: 40px;
			color: $main-color;
		}
	}
	h3 {
		font-size: 24px;
		margin-bottom: 15px;
	}
	&:hover {
		&::before {
			left: 0;
			border-radius: 50%;
			animation: rotation 100s infinite linear;
		}
		.read-more {
			color: $main-color;
		}
	}
}
.technology-wrap {
	.single-technology {
		box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.05);
		padding: 20px;
		margin: {
			top: 30px;
			left: 15px;
			right: 15px;
		};
	}
	&.owl-theme {
		.owl-nav {
			margin-top: 0 !important;
			margin-top: 0 !important;
			opacity: 0;
			transition: $transition;

			.owl-prev {
				margin: 0 !important;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-25px);

				i {
					background-color: $main-color;
					border-radius: 50%;
					width: 40px;
					height: 40px;
					line-height: 40px;
					color: $white-color;
					font-size: 30px;
				}
			}
			.owl-next {
				margin: 0 !important;
				margin: 0 !important;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-25px);

				i {
					background-color: $main-color;
					border-radius: 50%;
					width: 40px;
					height: 40px;
					line-height: 40px;
					color: $white-color;
					font-size: 30px;
				}
			}
			[class*=owl-] {
				background-color: transparent;
			}
		}
	}
	&:hover {
		&.owl-theme {
			.owl-nav {
				opacity: 1;
			}
		}	
	}
}

/*
Customer Support Area Style
======================================================*/
.support-img {
	text-align: center;
}
.customer-title {
	h2 {
		font-size: 40px;
		margin-bottom: 15px;
	}
}
.support-list {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
	margin: {
		bottom: 0;
		top: 20px;
	};
	li {
		margin-bottom: 12px;
		color: $heading-color;
		position: relative;
		padding-left: 25px;
		font: {
			size: 15px;
			weight: 600;
		};
		&::before {
			content: "";
			position: absolute;
			top: 7px;
			left: 0;
			width: 11px;
			height: 11px;
			background-color: $heading-color;
		}
		&::after {
			content: "";
			position: absolute;
			top: 10px;
			left: 3px;
			width: 11px;
			height: 11px;
			background-color: $heading-color;
			z-index: -1;
			opacity: 0.5;
		}
	}
}

/*
Solutions Support Area Style
======================================================*/
.solutions-wrap {
	.solutions-title {
		h2 {
			font-size: 40px;
			margin: {
				top: -9px;
				bottom: 15px;
			};
		}
	}
	.solutions-list {
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 25px;
			bottom: 0;
		};
		li {
			margin-bottom: 10px;
			color: $heading-color;
			position: relative;
			padding-left: 30px;
			font: {
				size: 15px;
				weight: 600;
			};
			&:last-child {
				margin-bottom: 0;
			}
			i {
				font-size: 10px;
				background-color: #daf6fd;
				width: 20px;
				height: 20px;
				display: inline-block;
				line-height: 20px;
				text-align: center;
				border-radius: 50%;
				color: $main-color;
				transition: $transition;
				position: absolute;
				left: 0;
				top: 5px;
			}
			&:hover {
				i {
					background-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
	.default-btn {
		margin-top: 25px;
	}
}
.solutions-img {
	text-align: center;
}

/*
Hosting Solution Area Style
======================================================*/
.hosting-solution-area {
	background-image: linear-gradient(to bottom right, rgb(4, 6, 60), rgb(28, 83, 123));
	position: relative;
	overflow: hidden;

	.shape-one {
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: 400px;
		animation: movebounce 5s linear infinite;
	}
	.shape-two {
		position: absolute;
		top: 50px;
		right: 0;
		animation: movebounce 9s linear infinite;
	}
}
.hosting-solution-wrap {
	text-align: center;
	max-width: 550px;
	margin: auto;

	h2 {
		font-size: 40px;
		color: $white-color;
		margin: {
			top: -9px;
			bottom: 30px;
		};
	}
	p {
		color: $white-color;
		margin-bottom: 30px;
	}
	.default-btn {
		margin: 0 10px;

		&.active {
			color: $body-color;

			&::before {
				background: $white-color;
			}
			&:hover {
				color: $white-color;
			}
		}
	}
}



/*
Server Area Style
======================================================*/
.server-content-wrap {
	h2 {
		font-size: 40px;
		margin: {
			top: -9px;
			bottom: 12px;
		};
	}
	ul {
		padding-left: 0;
		list-style-type: none;
		margin: {
			bottom: 0;
			top: 25px;
		};
		li {
			position: relative;
			padding-left: 70px;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
			span {
				color: $heading-color;
				margin-bottom: 5px;
				display: block;
				font: {
					size: 23px;
					weight: 700;
					family: $heading-font-family;
				};
			}
			i {
				font-size: 50px;
				position: absolute;
				top: 10px;
				left: 0;
				line-height: 1;
				background-image: linear-gradient(to bottom, rgb(14, 173, 211), rgb(155, 50, 254));
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			&::before {
				content: "";
				position: absolute;
				top: 30px;
				left: -7px;
				width: 40px;
				height: 40px;
				background-color: $main-color;
				border-radius: 50%;
				opacity: 0.1;
			}
		}
	}
}
.server-img {
	animation: movebounce 5s linear infinite;
	text-align: center;
}

/*
Locations Area Style
======================================================*/
.location-area {
	background-color: #04063c;
	text-align: center;

	.section-title {
		h2 {
			color: $white-color;
		}
		p {
			color: $white-color;
		}
	}
	.by-domain {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			display: inline-block;
			color: $white-color;
			padding: 0 10px;

			span {
				color: $white-color;
				font-weight: 600;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}
}
.map-img {
	position: relative;
	max-width: 600px;
	margin: 0 auto 30px;

	.single-location {
		position: absolute;
		top: 80px;
    	left: 280px;
		cursor: pointer;

		&.video-btn {
			display: inline-block;
			width: 30px;
			height: 30px;
			line-height: 30px;

			i {
				font-size: 20px;
				top: 4px;
				left: -1px;
				transition: $transition;
				position: relative;
			}
		}
		span {
			font-weight: 600;
			opacity: 0;
			transition: $transition;
			position: relative;
			left: -15px;
			top: 20px;
			background-color: $white-color;
			padding: 10px;
			color: $heading-color;
			border-radius: $border-radius;
		}
		&.location-two {
			left: 300px;
    		top: 35px
		}
		&.location-three {
			left: 300px;
    		top: 200px;
		}
		&.location-four {
			left: 450px;
			top: 100px;
		}
		&.location-five {
			left: 520px;
			top: 300px;
		}
		&.location-six {
			left: 130px;
			top: 250px;
		}
		&:hover {
			span {
				opacity: 1;
			}
		}
	}
}

/*
Frequently Area Style
======================================================*/
.frequently-content-wrap {
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			position: relative;
			transition: $transition;
			margin-bottom: 30px;
			box-shadow: $box-shadow;
			background-color: $white-color;
			padding: {
				top: 20px;
				bottom: 20px;
				right: 20px;
				left: 110px;
			};
			i {
				font-size: 35px;
				color: $white-color;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 20px;
				line-height: 70px;
				background-color: $main-color;
				border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
				width: 70px;
				height: 70px;
				text-align: center;
				transition: all 0.5s;
			}
			&:last-child {
				margin-bottom: 0;
			}
			h3 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			&:hover {
				transform: translateY(-5px);

				i {
					border-radius: 50%;
					box-shadow: $box-shadow;
					background-color: $white-color;
					color: $main-color;
				}
			}
		}
	}
}
.frequently-img {
	animation: movebounce 5s linear infinite;
	text-align: center;
}

/*
Client Area Style
======================================================*/
.client-area {
	background-image: linear-gradient(to bottom right, rgb(73, 117, 149), rgb(54, 56, 99));
	position: relative;
	overflow: hidden;

	.section-title {
		h2 {
			color: $white-color;
		}
		p {
			color: $white-color;
		}
	}
	.owl-carousel .owl-item img {
		width: unset;
		margin: auto;
	}
	.owl-theme {
		.owl-dots {
			line-height: 1;

			.owl-dot {
				span {
					width: 15px;
					height: 6px;
					margin: 0 5px;
					background: #D6D6D6;
					border-radius: 0 !important;
					transition: $transition;
				}
				&.active {
					span {
						width: 20px;
						background-color: $main-color;
					}
				}
				&:hover {
					span {
						background-color: $main-color;
					}
				}
			}
		}
	}
	.shape-ome {
		position: absolute;
		bottom: 0;
		right: 0;
		animation: movebounce 5s linear infinite;
	}
}
.single-client {
	background-color: $white-color;
	text-align: center;
	padding: 0 30px 30px 30px;
	border-radius: $border-radius;
	box-shadow: -9px 9px 0px 0px #eee;
	margin: {
		bottom: 30px;
		top: 30px;
	};
	img {
		border-radius: 50%;
		margin-bottom: -30px !important;
		position: relative;
		top: -30px;
		width: unset;
	}
	h3 {
		font: {
			size: 22px;
		};
		margin: {
			top: 20px;
			bottom: 5px;
		};
	}
	p {
		margin-bottom: 12px;
	}
	span {
		margin-bottom: 10px;
		display: block;
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin: {
				left: 2px;
				right: 2px;
			};
			i {
				color: $main-color;
			}
		}
	}
}
.single-client-area {
	.single-client {
		margin-bottom: 30px;
	}
}

/*
Blog Area Style
======================================================*/
.single-blog-post {
	background-color: #d2ecfe;
	text-align: center;
	transition: $transition;
	margin-bottom: 30px;

	.blog-content-wrap {
		text-align: left;
		box-shadow: $box-shadow;
		background-color: $white-color;
		padding: 30px;

		ul {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 10px;

			li {
				display: inline-block;
				margin-right: 15px;

				&:last-child {
					margin-right: 0;
				}
				i {
					color: $main-color;
					margin-right: 2px;
				}
				a {
					color: $body-color;
					display: inline-block;

					&:hover {
						color: $main-color;
					}
				}
			}
		}
		h3 {
			margin-bottom: 12px;
			line-height: 1.3;
			font: {
				size: 24px;
			};
			a {
				display: inline-block;
			}
		}
	}
}

/*
Footer Top Area Style
======================================================*/
.footer-top-area {
	background-image: linear-gradient(to bottom right, rgb(4, 24, 60), rgb(0, 3, 30));
}
.single-footer-widget {
	margin-bottom: 30px;

	p {
		color: #d9d6d6;
		margin-bottom: 20px;
	}
	.logo {
		display: inline-block;
		margin-bottom: 25px;
	}
	h3 {
		color: $white-color;
		margin-bottom: 25px;
		font-size: 24px;
	}
	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			margin-bottom: 10px;
			font-weight: 500;

			&:last-child {
				margin-bottom: 0;
			}
			a {
				color: #d9d6d6;
				display: inline-block;

				&:hover {
					color: $main-color;
				}
			}
		}
	}
	.social-icon {
		li {
			display: inline-block;
			margin: {
				right: 8px;
				bottom: 0;
			};
			a {
				width: 32px;
				height: 32px;
				line-height: 30px;
				border: 1px solid $white-color;
				color: $white-color;
				text-align: center;
				display: block;
				font-size: 15px;

				&:hover {
					background-color: $main-color;
					border-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
}

/*
Footer Bottom Area Style
======================================================*/
.footer-bottom-area {
	background-color: #00051e;
	padding: 15px 0;

	p {
		color: #d9d6d6;

		i {
			position: relative;
			top: 1px;
		}
		a {
			color: $white-color;
			font-weight: 600;

			&:hover {
				color: $main-color;
			}
		}
	}
	.designed {
		text-align: right;

		i {
			color: $main-color;
			display: inline-block;
			margin-right: 4px;
		}
	}
	ul {
		text-align: center;
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin: {
				left: 14px;
				right: 14px;
			};
			a {
				color: #d9d6d6;
				position: relative;
				display: block;

				&::before {
					content: "";
					position: absolute;
					top: 13px;
					left: -18px;
					width: 10px;
					height: 1px;
					background-color: $white-color;
				}
				&:hover {
					color: $main-color;
				}
			}
			&:first-child {
				margin-left: 0;

				a {
					&::before {
						display: none;
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

/*====================================================
HOME TWO STYLE
======================================================*/

/*
Main Banner Two Area Style
======================================================*/
.main-banner-area-two {
	height: 100vh;
	background: {
		position: center center;
		size: cover;
		repeat: no-repeat;
		color: transparent;
		image: url(assets/img/banner-two/banner-two-bg.jpg);
	};
	.banner-text {
		margin-top: 60px;
	}
	.banner-img {
		position: relative;
		z-index: 1;
		top: 0;

		img {
			position: absolute;

			&:nth-child(1) {
				top: 35px;
				right: 0;
				left: 0;
				margin: auto;
			}
			&:nth-child(2) {
				top: 55px;
				left: -45px;
			}
			&:nth-child(3) {
				top: 190px;
				left: 150px;
			}
			&:nth-child(4) {
				top: 250px;
				left: 350px;
			}
			&:nth-child(5) {
				top: 130px;
    			right: 10px;
			}
			&:nth-child(6) {
				top: -30px;
   				right: 45px
			}
			&:nth-child(7) {
				top: 0;
				z-index: -1;
				animation: focus-one 4s linear 4s infinite;
			}
		}
	}
}

/*
Search Domain Area Style
======================================================*/
.search-domain-area {
	background-color: #efedf1;

	.search-text {
		h2 {
			font-size: 40px;
			margin-bottom: 0;
		}
	}
	ul {
		text-align: center;
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 30px;
			bottom: 0;
		};
		li {
			display: inline-block;
			color: #9b9b9b;
			padding: 0 15px;
			font: {
				size: 14px;
				weight: 600;
			};
			span {
				margin: 0 5px;
			}
			&:nth-child(1) {
				span {
					color: #ff4b2b;
				}
			}
			&:nth-child(2) {
				span {
					color: #22105f;
				}
			}
			&:nth-child(3) {
				span {
					color: #1fa2e1;
				}
			}
			&:nth-child(4) {
				span {
					color: #d84126;
				}
			}
			&:nth-child(5) {
				span {
					color: #0371ec;
				}
			}
			&:nth-child(6) {
				span {
					color: #b86d60;
				}
			}
		}
	}
	
}

/*
Services Area Style
======================================================*/
.services-area {
	.single-technology {
		background-color: transparent;
		box-shadow: none;

		&:hover {
			box-shadow: $box-shadow;
			background-color: $white-color;
		}
		&.active {
			box-shadow: $box-shadow;
			background-color: $white-color;
			
			&::before {
				left: 0;
			}
		}
	}
}

/*
Choose Area Style
======================================================*/
.choose-area {
	background-color: #12abd5;

	.choose-content-wrap {
		h2 {
			font-size: 40px;
			margin-bottom: 15px;
			color: $white-color;
		}
		p {
			color: $white-color;
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin: {
				bottom: 0;
				top: 25px;
			};
			li {
				position: relative;
				padding-left: 65px;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
				span {
					color: $white-color;
					margin-bottom: 5px;
					display: block;
					font: {
						size: 22px;
						weight: 700;
						family: $heading-font-family;
					};
				}
				i {
					font-size: 30px;
					position: absolute;
					top: 10px;
					left: 0;
					background-color: $white-color;
					color: $heading-color;
					font-weight: 700;
					width: 50px;
					height: 50px;
					text-align: center;
					line-height: 50px;
					border-radius: 50%;
				}
				&::before {
					content: "";
					position: absolute;
					top: 30px;
					left: -7px;
					width: 40px;
					height: 40px;
					background-color: $main-color;
					border-radius: 50%;
					opacity: 0.1;
				}
			}
		}
	}
}

/*
Try Now Free Area Style
======================================================*/
.try-now-free {
	background-color: $white-color;
	box-shadow: $box-shadow;

	h3 {
		margin-bottom: 0;
		font-size: 30px;
	}
}

/*====================================================
HOME THREE STYLE
======================================================*/

/*
Main Banner Area Three Style
======================================================*/
.main-banner-area-three {
	background-color: #04063c;
	position: relative;

	.banner-text {
		margin-top: 140px;
	}
	.banner-img {
		text-align: center;
		display: block !important;

		.banner-main-img {
			animation: movebounce 5s linear infinite;
			display: inline-table;
		}
		.banner-main-img-shape {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			left: 0;
			justify-content: center;
			align-items: center;
			display: flex;
			animation: rotation 100s infinite linear;
			opacity: 0.4;
		}
	}
}

/*
Team Area Three Style
======================================================*/
.single-team {
	position: relative;
	margin-bottom: 30px;
	text-align: center;
	z-index: 1;
	overflow: hidden;

	.team-man {
		position: absolute;
		top: -70px;
		left: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		z-index: -1;
		transition: $transition;
	}
	h3 {
		margin: {
			top: 20px;
			bottom: 6px;
		};
		font: {
			size: 24px;
		};
	}
	span {
		margin-bottom: 15px;
		display: block;
	}
	.social-icon {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin: {
				left: 3px;
				right: 3px;
			};
			a {
				width: 30px;
				display: block;
				height: 30px;
				line-height: 30px;
				border: 1px solid $heading-color;
				color: $heading-color;
				border-radius: 50%;
				text-align: center;

				&:hover {
					background-color: $main-color;
					border-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
	&:hover {
		.team-man {
			animation: movebounce 5s linear infinite;
		}
	}
}

/*
Contact Area Three Style
======================================================*/
.contact-area {
	background-color: #0a0d39;

	.section-title {
		h2 {
			color: $white-color;
		}
		p {
			color: $white-color;
		}
	}
	label {
		color: $white-color;
	}
	.form-control {
		color: $white-color;
		background-color: #1e2258;
		border-color: #1e2258;
		border-radius: $border-radius;

		&::placeholder {
			color: #cac4c4 !important;
		}
		&:focus {
			background-color: transparent;
		}
	}
	.form-group {
		margin-bottom: 30px;
	}
}

/*====================================================
HOME FOUR STYLE
======================================================*/

/*
Main Banner Four Area Style
======================================================*/
.main-banner-area-four {
	.banner-text {
		margin-top: -120px;
	}
	.banner-img {
		position: relative;
		animation: movebounce 5s linear infinite;
		top: -50px;

		.banner-img-part-one {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
		}
	}
}

/*
Search Domain Area Style
======================================================*/
.search-domain-area-four {
	background-color: #efedf1;
	max-width: 1000px;
	margin: auto;
	padding: 50px;
	margin-top: -110px;
	border-radius: $border-radius;
	position: relative;
	z-index: 1;

	ul {
		li {
			padding: 0 10px;
		}
	}
}

/*
Hosting Service Area Style
======================================================*/
.single-hosting-service {
	position: relative;
	margin-bottom: 30px;
	border: 1px dashed $heading-color;
	z-index: 1;
	transition: $transition;
	padding: {
		bottom: 30px;
		top: 30px;
		left: 95px;
		right: 20px;
	};
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: $main-color;
		z-index: -1;
		transition: $transition;
	}
	i {
		position: absolute;
		top: 30px;
		left: 20px;
		font-size: 28px;
		color: $white-color;
		background-color: $heading-color;
		height: 60px;
		width: 60px;
		text-align: center;
		line-height: 60px;
		border-radius: 50%;
		transition: $transition;
	}
	h3 {
		transition: $transition;
		margin-bottom: 8px;
		font-size: 24px;
	}
	p {
		transition: $transition;
		margin-bottom: 0;
	}
	&:hover {
		border-color: $main-color;

		&::before {
			width: 100%;
		}
		i {
			transform: rotate(360deg);
			background-color: $white-color;
			color: $main-color;
		}
		h3 {
			color: $white-color;
		}
		p {
			color: $white-color;
		}
	}
}

/*
Shared Hosting Area Style
======================================================*/
.hosting-list {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;

	li {
		margin-bottom: 10px;
		
		&:last-child {
			margin-bottom: 0;
		}
		a {
			color: $heading-color;
			background-color: $white-color;
			box-shadow: $box-shadow;
			padding: 20px;
			display: block;
			text-align: center;
			position: relative;
			z-index: 1;
			transition: $transition;
			font: {
				size: 20px;
				weight: 700;
				family: $heading-font-family;
			};
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 100%;
				z-index: -1;
				background-color: $main-color;
				transition: $transition;
			}
		}
		&:hover {
			a {
				color: $white-color;

				&::before {
					width: 100%;
				}
			}
		}
	}
}
.shared-hosting-content {
	h2 {
		font-size: 40px;
		margin-bottom: 20px;
	}
}
.shared-hosting-list {
	padding-left: 0;
	list-style-type: none;
	margin: {
		top: 20px;
		bottom: 0;
	};
	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 12px;
		font: {
			size: 15px;
			weight: 600;
		};
		&:last-child {
			margin-bottom: 0;
		}
		i {
			color: $main-color;
			position: absolute;
			top: 1px;
			left: 0;
		}
	}
}
.special-intro-offer-wrap {
	margin-top: 100px;

	.special-intro-offer {
		text-align: center;
		background-color: $white-color;
		padding: 30px;
		margin: auto;
		border-radius: $border-radius;

		h3 {
			margin-bottom: 20px;
			font-size: 24px;
		}
		span {
			display: block;
			color: $main-color;
			line-height: 1;
			margin-bottom: 20px;
			font: {
				size: 50px;
				weight: 700;
				family: $heading-font-family;
			};
			sub {
				font-size: 14px;
				color: $body-color;
			}
		}
		p {
			margin-bottom: 20px;
		}
	}
	.special-intro-offer-list {
		ul {
			float: unset !important;

			li {
				display: block !important; 
				list-style: none;
				color: $white-color !important;
				position: relative;
				font-weight: 600 !important;
				padding-left: 25px !important;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
				&:before {
					display: none;
				}
				i {
					position: absolute;
					top: 5px;
					left: 0;
					width: 16px;
					height: 16px;
					line-height: 16px;
					border-radius: 50%;
					background-color: $white-color;
					color: $main-color;
					font-size: 10px;
					text-align: center;
				}
			}
		}
	}
}
.reseller-hosting-title {
	padding-bottom: 70px !important;
}
.reseller-item {
	border: 1px dashed $white-color;
	padding: 30px;
	text-align: center;
	transition: $transition;
	margin-bottom: 30px;

	i {
		color: $main-color;
		font-size: 25px;
		line-height: 1;
		width: 50px;
		height: 50px;
		line-height: 50px;
		background-color: $white-color;
		border-radius: 50%;
		display: inline-block;
		margin-bottom: 20px;
		transition: $transition;
	}
	h3 {
		color: $white-color;
		margin-bottom: 12px;
		transition: $transition;
		font-size: 24px;
	}
	p {
		color: $white-color;
		transition: $transition;
	}
	&:hover {
		background-color: $main-color;
	}
}

/*
Client Area Four Style
======================================================*/
.client-area-four {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 200px;
		background-color: $white-color;
	}
	.single-client {
		box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.05);
		margin: {
			right: 10px;
			left: 10px;
		};
	}
}
/*
Brand Area Style
======================================================*/
.brand-area {
	margin-top: -30px;
}
.single-brand {
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 20px 30px;
	border-radius: $border-radius;
	transition: $transition;
	box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.05);
	margin: {
		right: 10px;
		left: 10px;
		top: 30px;
		bottom: 30px;
	};
	img {
		height: 125px;
		margin: auto;
	}
	&:hover {
		background-color: $heading-color;
	}
}

/*====================================================
HOME FIVE STYLE
======================================================*/

/*
Main Slider Area Style
======================================================*/ 
.main-slider-area {
	height: 100vh;

	.banner-text {
		margin-top: 70px !important;
	}
	.owl-carousel {
		.owl-item {
			img {
				width: unset;
			}
		}
	}
	.main-slider-item {
		height: 100vh;
	}
	.owl-carousel .owl-stage-outer {
		overflow: none;
	}
	.owl-item {
		&.active {
			.banner-img {
				top: 80px;

				.animation-1 {
					animation: 1s .1s fadeInDown both;
				}
				.animation-2 {
					animation: 2s .2s fadeInDown both;
				}
				.animation-3 {
					animation: 3s .3s fadeInDown both;
				}
				.animation-4 {
					animation: 4s .4s fadeInDown both;
				}
				.animation-5 {
					animation: 5s .5s fadeInDown both;
				}
				.animation-6 {
					animation: 5s .5s fadeInDown both;
				}
				.animation-7 {
					animation: 5s .5s fadeInDown both;
				}
				.animation-8 {
					animation: 5s .5s fadeInDown both;
				}
			}
		}
	}
	.banner-img img:nth-child(3) {
		top: -60px !important;
	}
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -60px;
		transform: translateY(5px);
		transition: $transition;

		i {
			font-size: 30px;
			background-color: $white-color;
			width: 50px;
			height: 50px;
			line-height: 50px;
			display: inline-block;
			color: $main-color;
			transition: $transition;
		}
		&:hover {
			i {
				background-color: $main-color;
				color: $white-color;
			}
		}
	}
	.owl-next {
		position: absolute; 
		top: 50%;
		right: -60px;
		transform: translateY(5px);
		transition: $transition;

		i {
			font-size: 30px;
			background-color: $white-color;
			width: 50px;
			height: 50px;
			line-height: 50px;
			display: inline-block;
			color: $main-color;
			transition: $transition;
		}
		&:hover {
			i {
				background-color: $main-color;
				color: $white-color;
			}
		}
	}
	&:hover {
		.owl-prev {
			left: 0;
		}
		.owl-next {
			right: 0;
		}
	}
}

/*====================================================
INNER PAGE STYLE
======================================================*/

/* Page Title Area Style
=====================================================*/
.page-title-area {
	position: relative;
	background-color: #04063c;
	overflow: hidden;
	z-index: 1;
	padding: {
		top: 200px;
		bottom: 150px;
	};
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 100px;
		width: 100%;
		height: 100%;
		z-index: -1;
		animation: moVEbounce 10s linear infinite;
		background: {
			position: top;
			size: cover;
			repeat: no-repeat;
			image: url(assets/img/page-bg.png);
		};
	}
	.page-title-content {
		h2 {
			margin-bottom: 0;
			color: $white-color;
			font: {
				size: 40px;
				weight: 700;
			};
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			float: right;
			margin: {
				top: 10px;
				bottom: 0;
			};
			li {
				display: inline-block;
				position: relative;
				padding-left: 25px;
				color: $main-color;
				font: {
					size: 15px;
					weight: 500;
				};
				&::before {
					content: "";
					position: absolute;
					top: 12px;
					right: -15px;
					background-color: $main-color;
					width: 5px;
					height: 5px;
					border-radius: 50%;
				}
				&:last-child {
					&::before {
						display: none;
					}
				}
				a {
					color: $white-color;

					&:hover {
						color: $main-color;
					}
				}
			}
		}
	}
}

/* 
FAQ Area Style
=====================================================*/
.faq-area {
	.faq-accordion {
		.accordion {
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;

			.accordion-item {
				display: block;
				box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
				background: $white-color;

				&:last-child {
					margin-bottom: 0;
				}
			}
			.accordion-title {
				padding: 12px 20px 12px 60px;
				color: $heading-color;
				position: relative;
				border-bottom: 1px dashed #eeeeee;
				margin-bottom: -1px;
				display: block;
				font-size: 18px;

				i {
					position: absolute;
					left: 0;
					top: 0;
					width: 40px;
					text-align: center;
					height: 100%;
					background: #faf5f5;
					color: $main-color;
					font-size: 14px;
					-webkit-transition: 0.5s;
					transition: 0.5s;

					&::before {
						position: absolute;
						left: 0;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}
				}
				span {
					position: absolute;
					top: 14px;
					left: 20px;
					width: 30px;
					height: 30px;
					line-height: 30px;
					background-color: $main-color;
					color: $white-color;
					text-align: center;
					border-radius: 50%;
				}
			}
			.accordion-title.active {
				border-bottom-color: $main-color;

				i {
					background-color: $main-color;
					color: $white-color;

					&::before {
						content: "\f063";
					}
				}
			}
			.accordion-content {
				display: none;
				position: relative;
				padding: 30px;
			}
			.accordion-content.show {
				display: block;
			}
		}
	}
}

/*
Ask Question Area Style
=====================================================*/
.ask-question {
	background-color: $white-color;
	box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
	padding: 50px;

	.section-title {
		h2 {
			margin-bottom: 0;
		}
	}
	h3 {
		margin-bottom: 20px;
	}
	.form-group {
		margin-bottom: 30px;
	}
}

/*
Sign In & Log In  Area Style
=====================================================*/
.user-area-all-style {
	position: relative;
	z-index: 1;
	overflow: hidden;
	
	.contact-form-action {
		background-color: $white-color;
		border-radius: 4px;
		box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
		padding: 40px;
		max-width: 570px;
		margin: auto;

		.form-heading {
			margin-bottom: 33px;

			.form-title {
				font-size: 38px;
				color: #233d63;
				margin-bottom: 10px;
				text-transform: capitalize;
			}
			.form-desc {
				font-size: 18px;
				color: #677286;
				font-weight: 500;
			}
			.reset-desc {
				line-height: 30px;
				margin-top: 15px;

				a {
					color: $main-color;
				}
			}
			.reset-desc {
				font-size: 16px;

				a {
					font-weight: 600;
				}
			}
		}
		.default-btn {
			display: block;
			width: 100%;
			text-align: center;
			padding: 11px 25px;
		}
		form {
			.submit-btn {
				margin-top: 25px;
			}
			.default-btn {
				margin-bottom: 20px;
			}
			.reset-btn {
				margin-top: 0;
			}
			.form-control {
				font-size: 15px;
			}
			.form-condition {
				margin-bottom: 20px;

				.agree-label {
					font-weight: 600;

					a {
						color: $main-color;
					}
				}
			}
			.form-group {
				margin-bottom: 20px;
			}
		}
		.account-desc {
			margin: 0 auto;
			display: table;
			font-weight: 600;

			a {
				color: $main-color;
				margin-left: 3px;
				font-weight: 600;
			}
		}
		#chb1 {
			position: relative;
			top: 1px;
			margin-right: 3px;
		}
		#chb2 {
			position: relative;
			top: 1px;
			margin-right: 3px;
		}
		.forget {
			float: right;
			color: $main-color;
			font-weight: 600;
		}
		.now-register {
			font-weight: 600;
			float: right;

			a {
				color: $main-color;
			}
		}
		.now-log-in {
			color: $main-color;
			font-weight: 600;

			.font-q {
				font-weight: 600;
			}
		}
		.recover-password-btn {
			margin: {
				bottom: 0;
				top: 20px;
			};
		}
	}
}
.single-privacy {
	h3 {
		font-size: 24px;
		margin: {
			top: 30px;
			bottom: 15px;
		};
	}
}

/* 
Coming Soon Area Style
=====================================================*/
.coming-soon-area {
	position: relative;
	height: 100vh;
	z-index: 1;
	overflow: hidden;
	background: {
		image: url(assets/img/coming-soon-bg.png);
		repeat: no-repeat;
		position: center center;
		size: cover;
	};
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $main-color;
		opacity: 0.9;
		z-index: 1;
	}
	.coming-soon-content {
		text-align: center;
		max-width: 750px;
		position: relative;
		z-index: 2;
		margin: {
			left: auto;
			right: auto;
		};
		h1 {
			color: $white-color;
			font-size: 60px;
			margin: {
				top: -18px;
				bottom: 0;
			};
		}
		p {
			color: #f3f3f3;
			font-size: 14.5px;
			max-width: 600px;
			margin: {
				top: 15px;
				bottom: 0;
				left: auto;
				right: auto;
			};
		}
		#timer {
			margin-top: 30px;

			div {
				display: inline-block;
				color: $white-color;
				position: relative;
				margin: {
					left: 35px;
					right: 35px;
				};
				font: {
					size: 45px;
					weight: 700;
				};
				span {
					display: block;
					text-transform: capitalize;
					margin-top: -15px;
					font: {
						size: 16px;
						weight: normal;
					};
				}
				&:last-child {
					margin-right: 0;

					&::before {
						display: none;
					}
				}
				&:first-child {
					margin-left: 0;
				}
				&::before {
					content: "";
					position: absolute;
					right: -50px;
					top: -10px;
					font-size: 70px;
					color: $white-color;
				}
			}
		}
		.newsletter-form {
			position: relative;
			max-width: 500px;
			margin: {
				left: auto;
				right: auto;
				top: 50px;
			};
			.input-newsletter {
				display: block;
				width: 100%;
				height: 60px;
				border: none;
				background-color: rgba(255, 255, 255, 0.3);
				padding-left: 15px;
				color: $white-color;
				outline: 0;
				transition: 0.5s;
				border-radius: 50px;

				&::placeholder {
					color: $white-color;
				}
				&:focus {
					border-color: $main-color;
				}
			}
			button {
				position: absolute;
				right: 0;
				top: 0;
				height: 60px;
				padding: 0 30px;
				text-transform: uppercase;
				outline: 0;
				color: $white-color;
				background-color: transparent;
				transition: 0.5s;
				cursor: pointer;
				font: {
					weight: 500;
					size: 15px;
				};
				&::after {
					border-radius: 0;
				}
				&::before {
					border-radius: 0;
				}
				&:hover {
					color: $white-color;
				}
			}
			#validator-newsletter {
				color: $white-color;
				font-size: 14px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: -30px;
				margin: 0 auto;
			}
		}
	}
} 

/*
404 Error Area Style
=====================================================*/
.error-area {
    z-index: 1;
	padding: 50px 0;
    text-align: center;
    position: relative;
	background-color: $black-color;
	padding: {
		top: 150px;
		bottom: 100px;
	};
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        z-index: -1;
    }
    .error-contact-wrap {
        z-index: 1;
		position: relative;

		h1 {
			font-size: 300px;
			color: $white-color;
			line-height: 1;

			span {
				background-image: linear-gradient(to bottom right, #ff416c, #fa4612);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
        h3 {
            margin: 30px 0 0;
			position: relative;
			color: #ff0000;
        }
        p {
			margin: 20px 0 20px;
			font-size: 19px;
			color: $white-color;
        }
    }
}

/*
Affiliate Area Style
=====================================================*/
.affiliate-content-wrap {
	max-width: 600px;
	margin: 50px auto 0;
	text-align: center;

	h3 {
		color: $white-color;
		font-size: 30px;
		margin-bottom: 20px;
	}
	p {
		color: $white-color;
		margin-bottom: 20px;
	}
}
.single-affiliate-info {
	text-align: center;
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	transition: $transition;
	margin-bottom: 30px;

	i {
		color: $white-color;
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		background-color: $main-color;
		font-size: 30px;
		border-radius: 50%;
		margin-bottom: 25px;
	}
	h3 {
		margin-bottom: 12px;
		font-size: 24px;
	}
	p {
		margin-bottom: 20px;

		a {
			color: $main-color;

			&:hover {
				color: $heading-color;
			}
		}
	}
	.default-btn {
		padding: 10px 35px;
		display: block;
	}
	&:hover {
		transform: translateY(-5px);
	}
}
.single-work {
	position: relative;
    margin-bottom: 30px;
	padding-left: 105px;
	
	span {
		display: inline-block;
		width: 80px;
		height: 80px;
		line-height: 80px;
		background-color: $main-color;
		color: $white-color;
		position: absolute;
		text-align: center;
		font-weight: 700;
		top: 0;
		left: 0;
		border-radius: 50%;
		font-size: 50px;
	}
	h3 {
		font-size: 24px;
		margin-bottom: 12px;
	}
}

/*
Domain Banner Area Style
=====================================================*/
.domain-banner-area {
	background-color: #04063c;
	height: 700px;
	position: relative;
	z-index: 1;
	text-align: center;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		top: 100px;
		left: 0;
		width: 100%;
		height: 100%;
		animation: moVEbounce 5s linear infinite;
		z-index: -1;
		background: {
			position: top;
			repeat: no-repeat;
			size: cover;
			image: url(assets/img/page-bg.png);
		};
	}
	.banner-text {
		max-width: 600px;
		margin: auto;
		margin-top: 70px;

		h1 {
			color: $white-color;
			font-size: 50px;
			margin-bottom: 32px;
		}
		p {
			color: $white-color;
			font-size: 16px;
			margin-bottom: 40px;
			text-transform: capitalize;
		}
		.banner-btn {
			.default-btn {
				margin-right: 15px;
			}
		}
	}
}
.single-domain {
	background-color: $white-color;
	box-shadow: $box-shadow;
	padding: 30px;
	text-align: center;
	transition: $transition;
	border-radius: $border-radius;
	margin-bottom: 30px;

	.domain-name-wrap {
		position: relative;
		margin-bottom: 25px;

		.domain-name {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			
			h3 {
				font-size: 25px;
			}
		}
	}
	p {
		margin-bottom: 20px;
		transition: $transition;
	}
	span {
		color: $heading-color;
		margin-bottom: 20px;
		transition: $transition;
		display: block;
		font: {
			size: 20px;
			weight: 700;
		};
		sub {
			color: $body-color;
			transition: $transition;
			font: {
				size: 12px;
				weight: 500px;
			};
		}
	}
	h3 {
		font-size: 24px;
		margin-bottom: 0;
	}
	.default-btn {
		background-color: $heading-color;
	}
	&:hover {
		background-color: $main-color;

		p {
			color: $white-color;
		}
		span {
			color: $white-color;

			sub {
				color: $white-color;
			}
		}
	}
}
.domain-pricing-list {
	h2 {
		margin-bottom: 40px;
		text-align: center;
		font-size: 35px;
	}
	.domain-price {
		margin-bottom: 0;

		.domain-mane-title {
			background-color: $main-color;

			&:hover {
				background-color: $main-color;
			}
		}
		tr {
			border: 1px solid #eeeeee;
			vertical-align: middle;
			transition: $transition;

			&:hover {
				background-color: #eeeeee;
			}
		}
		th {
			padding: 20px 30px;
			text-align: center;
			vertical-align: middle;
			color: $white-color;
			white-space: nowrap;
			font: {
				weight: 700;
				size: 18px;
				family: $heading-font-family;
			};
		}
		td {
			color: $heading-color;
			padding: 5px 58px;
			text-align: center;
			transition: $transition;
			position: relative;
			vertical-align: middle;
			white-space: nowrap;
			font: {
				size: 18px;
				weight: 700;
				family: $heading-font-family;
			}; 
			.sale {
				position: absolute;
				top: 18px;
				background-color: #fcbe17;
				line-height: 1;
				color: $white-color;
				font-size: 14px;
				padding: 3px 8px;
				border-radius: 50px;
				right: 15px;
			}
			.save {
				position: absolute;
				top: 16px;
				background-color: #2ebf6d;
				line-height: 1;
				color: $white-color;
				font-size: 14px;
				padding: 3px 8px;
				border-radius: 50px;
				right: -15px;
			}
		}
		.table-domain-price {
			color: $body-color;
		}
		.default-btn {
			padding: 8px 30px 9px;
		}
	}
}
.btn-center {
	display: table;	
	margin: auto;
}

/*
Pricing Area Style
=====================================================*/
.shared-hosting-area {
	.col-lg-3 {
		&:nth-child(1) {
			ul {
				border-left: 1px solid #eeeeee;
			}
		}
		&:nth-child(2) {
			ul {
				border-left: 1px solid #eeeeee;

				li {
					&:nth-child(10) {
						color: $main-color;
					}
					&:nth-child(11) {
						color: $main-color;
					}
					&:nth-child(12) {
						color: #fa4612;
					}
				}
			}
		}
		&:nth-child(3) {
			ul {
				border-left: 1px solid #eeeeee;

				li {
					&:nth-child(10) {
						color: $main-color;
					}
					&:nth-child(11) {
						color: $main-color;
					}
					&:nth-child(12) {
						color: $main-color;
					}
				}
			}
		}
		&:nth-child(4) {
			ul {
				border: {
					left: 1px solid #eeeeee;
					right: 1px solid #eeeeee;
				};
				li {
					&:nth-child(10) {
						color: $main-color;
					}
					&:nth-child(11) {
						color: $main-color;
					}
					&:nth-child(12) {
						color: $main-color;
					}
				}
			}
		}
		&:hover {
			&:nth-child(2) {
				ul {
					li {
						&:nth-child(10) {
							color: $white-color;
						}
						&:nth-child(11) {
							color: $white-color;
						}
					}
				}
			}
			&:nth-child(3) {
				ul {
					li {
						&:nth-child(10) {
							color: $white-color;
						}
						&:nth-child(11) {
							color: $white-color;
						}
						&:nth-child(12) {
							color: $white-color;
						}
					}
				}
			}
			&:nth-child(4) {
				ul {
					li {
						&:nth-child(10) {
							color: $white-color;
						}
						&:nth-child(11) {
							color: $white-color;
						}
						&:nth-child(12) {
							color: $white-color;
						}
					}
				}
			}
		}
	}
}
.single-shared-hosting {
	margin-bottom: 30px;

	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 30px;
			transition: $transition;
			font: {
				size: 14px;
				weight: 600;
			};
			.default-btn {
				padding: 12px 35px;
				display: block;
				text-align: center;
				margin: {
					top: 20px;
					bottom: 20px;
				};
			}
		}
		.price-top-title {
			color: $heading-color;
			padding: 30px 30px;
			background-color: $main-color;
			color: $white-color;
			transition: $transition;
			font: {
				size: 25px;
				family: $heading-font-family;
				weight: 700;
			};
		}
		.price-title {
			padding: 15px 30px;
			background-color: #f3f3f3;
			color: $heading-color;
			transition: $transition;
			font: {
				size: 18px;
				family: $heading-font-family;
				weight: 700;
			};
			sub {
				font-size: 14px;
				transition: $transition;
			}
		}
	}
	&:hover {
		ul {
			li {
				background-color: $main-color;
				color: $white-color;
			}
			.price-top-title {
				color: $white-color;
			}
			.price-title {
				color: $white-color;

				sub {
					color: $white-color;
				}
			}
		}
	}
}
.tab {
	&.quote-list-tab {
		text-align: center;

		.tabs {
			border-radius: 50px;
			display: inline-block;
			background-color: #04063c;
			margin-bottom: 30px;
			padding-left: 0;
			list-style-type: none;

			li {
				display: inline-block;
				padding: 0 10px;
				color: $heading-color;
				font: {
					family: $heading-font-family;
					size: 20px;
					weight: 700;
				};
				a {
					color: $main-color;
					padding: 10px 20px;
				}
			}
			.current {
				background-color: $main-color;
				border-radius: 50px;

				a {
					color: $white-color;
				}
			}
		}
	}
	
}
.tab {
	.tabs_item {
		display: none;

		&:first-child {
			display: block;
		}
	}
}

/*
Blog Details Area Style
=====================================================*/
.blog-details-area {
	.blog-details-desc {
		.article-image {
			position: relative;
			background-color: $heading-color;
		}
		.article-content {
			margin-top: 30px;

			.entry-meta {
				margin-bottom: -10px;

				ul {
					padding-left: 0;
					margin-bottom: 0; 
					list-style-type: none;

					li {
						position: relative;
						display: inline-block;
						color: $main-color;
						margin-right: 25px;
						font-size: 14px;

						span {
							display: inline-block;
							color: $main-color;
							font-weight: 500;
							margin-right: 2px;
						}
						a {
							display: inline-block;
							color: #666666;
							font-size: 13px;

							&:hover {
								color: $main-color;
							}
						}
						i {
							color: $main-color;
							margin-right: 2px;
						}
						&::before {
							content: '';
							position: absolute;
							top: 13px;
							right: -15px;
							width: 6px;
							height: 1px;
							background: $main-color;
						}
						&:last-child {
							margin-right: 0;

							&::before {
								display: none;
							}
						}
					}
				}
			}
			h3 {
				margin: {
					bottom: 13px;
					top: 25px;
				};
				font: {
					size: 24px;
				};
			}
			.wp-block-gallery.columns-3 {
				padding-left: 0;
				list-style-type: none;
				display: flex;
				flex-wrap: wrap;
				margin: {
					right: -10px;
					left: -10px;
					bottom: 30px;
					top: 30px;
				};
				li {
					flex: 0 0 33.3333%;
					max-width: 33.3333%;
					padding: {
						right: 10px;
						left: 10px;
					};
					figure {
						margin-bottom: 0;
					}
				}
			}
		}
		.article-footer {
			display: flex;
			flex-wrap: wrap;
			margin-top: 30px;

			.article-tags {
				flex: 0 0 50%;
				max-width: 50%;

				span {
					display: inline-block;
					color: $main-color;
					font-size: 20px;
					margin-right: 5px;
					position: relative;
					top: 4px;
				}
				a {
					display: inline-block;
					color: #666666;
					font-weight: 600;

					&:hover {
						color: $main-color;
					}
				}
			}
			.article-share {
				flex: 0 0 50%;
				max-width: 50%;

				.social {
					padding-left: 0;
					list-style-type: none;
					text-align: right;
					margin-bottom: 0;

					li {
						display: inline-block;
						margin-left: 5px;

						span {
							display: inline-block;
							margin-right: 2px;
							font-weight: 500;
							color: #666666;
						}
						a {
							display: block;
							color: $white-color;
							width: 32px;
							height: 32px;
							line-height: 33px;
							border-radius: 50%;
							background-color: $main-color;
							text-align: center;
							font-size: 14px;

							&:hover {
								background-color: $heading-color;
							}
						}
					}
				}
			}
		}
		.post-navigation {
			margin-top: 30px;
		}
	}
}
blockquote {
	overflow: hidden;
	background-color: #f7f7f7;
	padding: 50px !important;
	position: relative;
	z-index: 1;
	border-radius: $border-radius;
	margin: {
		bottom: 20px;
		top: 20px;
	};
	p {
		color: $heading-color;
		line-height: 1.6;
		margin-bottom: 0;
		font: {
			style: italic;
			weight: 600;
			size: 20px !important;
		};
	}
	cite {
		display: none;
	}
	&::before {
		color: $main-color;
		content: "\ed67";
		position: absolute;
		left: 50px;
		display: none;
		top: -50px;
		opacity: 0.5;
		z-index: -1;
		font: {
			family:'boxicons';
			size: 140px;
			weight: 900;
		};
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 3px;
		background-color: $main-color;
		margin: {
			top: 20px;
			bottom: 20px;
		};
	}
}
.post-navigation {
	border: {
		top: 1px solid #eeeeee;
		bottom: 1px solid #eeeeee;
	};
	padding: {
		top: 20px;
		bottom: 20px;
	};
	.navigation-links {
		display: flex;
		flex-wrap: wrap;

		.nav-previous {
			flex: 0 0 50%;
			max-width: 50%;

			a {
				i {
					margin-right: 0;
					font-size: 20px;
					position: relative;
					top: 4px;
				}
			}
		}
		.nav-next {
			flex: 0 0 50%;
			max-width: 50%;
			text-align: right;

			a {
				i {
					margin-left: 0;
					font-size: 20px;
					position: relative; 
					top: 4px;
				}
			}
		}
		div {
			a {
				display: inline-block;
				font-weight: 600;
				color: $heading-color;

				&:hover {
					color: $main-color;
				}
			}
		}
	}
}
.comments-area {
	padding: 25px;
	margin-top: 30px;
	box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
	background-color: $white-color;

	.comments-title {
		position: relative;
		margin-bottom: 30px;
		font-size: 24px;

		&::before {
			content: '';
			height: 25px;
			width: 3px;
			left: -25px;
			position: absolute;
			background: $main-color;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	ol {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.comment-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.children {
		margin-left: 40px;
	}
	.comment-body {
		border-bottom: 1px solid #eeeeee;
		padding-left: 70px;
		font-size: 14px;
		margin-bottom: 20px;
		padding-bottom: 20px;

		.reply {
			margin-top: 15px;

			a {
				border: 1px solid #ded9d9;
				color: $heading-color;
				display: inline-block;
				padding: 5px 20px;
				border-radius: 30px;
				text-transform: uppercase;
				position: relative;
				z-index: 1;
				font: {
					size: 13px;
					weight: 600;
				};
				&:hover {
					color: $white-color;
					background-color: $main-color;
					border-color: $main-color;
				}
			}
		}
	}
	.comment-meta {
		margin-bottom: .8em;
	}
	.comment-author {
		font-size: 16px;
		margin-bottom: 5px;
		position: relative;
		z-index: 2;

		.avatar {
			height: 50px;
			left: -70px;
			position: absolute;
			width: 50px;
			border-radius: 50%;
		}
		.fn {
			font-weight: 600;
			color: $heading-color;
			font-size: 18px;
		}
		.says {
			display: none;
		}
	}
	.comment-metadata {
		color: #666666;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		font: {
			size: 13px;
			weight: 500;
		};
		a {
			color: #666666;
			font-size: 11px;

			&:hover {
				color: $main-color;
			}
		}
	}
	.comment-content {
		p {
			font-size: 14px;
		}
	}
	.comment-respond {
		margin-top: 30px;

		.comment-reply-title {
			margin-bottom: 15px;
			position: relative;
			font-size: 24px;

			&::before {
				content: '';
				height: 25px;
				width: 3px;
				left: -25px;
				position: absolute;
				background: $main-color;
				top: 50%;
				transform: translateY(-50%);
			}
			#cancel-comment-reply-link {
				display: inline-block;
			}
		}
		.comment-form {
			overflow: hidden;
		}
		.comment-form-comment {
			margin-bottom: 30px;
			float: left;
			width: 100%;
		}
		label {
			display: block;
			font-weight: 500;
			color: $heading-color;
			margin-bottom: 5px;
		}
		
		input[type="datetime-local"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="week"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;  
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="month"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="text"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="email"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="url"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="password"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;
			
			&:focus {
				border-color: $main-color;
			}
		}
		input[type="search"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="tel"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		input[type="number"] {
			display: block;
			width: 100%;
			background-color: #f7f7f7;
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		textarea {
			display: block;
			width: 100%;
			background-color: #f7f7f7; 
			border: 1px solid #eeeeee;
			padding: 0.625em 0.7375em;
			outline: 0;
			transition: 0.5s;

			&:focus {
				border-color: $main-color;
			}
		}
		.comment-form-author {
			float: left;
			width: 50%;
			padding-right: 10px;
			margin-bottom: 20px;
		}
		.comment-form-email {
			float: left;
			width: 50%;
			padding-left: 12px;
			margin-bottom: 20px;
		}
		.comment-form-url {
			float: left;
			width: 100%;
			margin-bottom: 20px;
		}
		.comment-form-cookies-consent {
			width: 100%;
			float: left;
			position: relative;
			padding-left: 20px;
			margin-bottom: 20px;

			input {
				position: absolute;
				left: 0;
				top: 6px;
			}
			label {
				display: inline-block;
				margin: 0;
				color: #666666;
				font-weight: normal;
			}
		}
		.form-submit {
			float: left;
			width: 100%;

			input {
				background: $main-color;
				border: none;
				color: $white-color;
				padding: 14px 30px 12px;
				display: inline-block;
				cursor: pointer;
				outline: 0;
				border-radius: 0;
				text-transform: uppercase;
				transition: 0.5s;
				font: {
					weight: 600;
					size: 15px;
				};
				&:hover {
					color: $white-color;
					background-color: $heading-color;
				}
				&:focus {
					color: $white-color;
					background-color: $main-color;  
				}
			}
		}
	}
}
	
/*
Sidebar Widget Area Style
================================*/
.widget-area {
	.widget {
		margin-top: 35px;
		background-color: $white-color;
		box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);

		.post-wrap {
			padding: 20px;
		}
		&:first-child {
			margin-top: 0;
		}
		.widget-title {
			text-transform: capitalize;
			position: relative;
			font-size: 22px;
			margin-bottom: 0;
			background-color: #f7f7f7;
			padding: 10px 20px;
			color: $heading-color;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 2px;
				height: 100%;
				background-color: $main-color;
			}
		}
	}
	.widget_search {
		form {
			position: relative;

			label {
				display: block;
				margin-bottom: 0;
			}
			.screen-reader-text {
				display: none;
			}
			.search-field {
				background-color: transparent;
				height: 50px;
				padding: 6px 15px;
				border: 1px solid #eeeeee;
				width: 100%;
				display: block;
				outline: 0;
				transition: 0.5s;

				&:focus {
					border-color:$main-color;
				}
			}
			button {
				position: absolute;
				right: 0;
				outline: 0;
				bottom: 0;
				height: 50px;
				width: 50px;
				border: none;
				color: $white-color;
				background-color: $main-color;
				transition: 0.5s;
				cursor: pointer;

				&:hover {
					background-color: $heading-color;
				}
			}
		}
	}
	.widget-hosta-posts-thumb {
		position: relative;
		overflow: hidden;

		.item {
			overflow: hidden;
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 1px solid #efefef;

			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
				padding-bottom: 0;
			}
			.thumb {
				float: left;
				height: 80px;
				overflow: hidden;
				position: relative;
				width: 80px;
				margin-right: 15px;

				.fullimage {
					width: 80px;
					height: 80px;
					position: relative;
					display: inline-block;
					background: {
						size: cover !important;
						repeat: no-repeat;
						position: center center !important;
						color: $heading-color;
					};
				}
				.fullimage.bg1 {
					background-image: url(assets/img/news-details/news-img-four.png);
				}
				.fullimage.bg2 {
					background-image: url(assets/img/news-details/news-img-five.png);
				}
				.fullimage.bg3 {
					background-image: url(assets/img/news-details/news-img-six.png);
				}
				.fullimage.bg4 {
					background-image: url(assets/img/news-details/news-img-seven.png);
				}
			}
			.info {
				overflow: hidden;
				span {
					display: block;
					color: #666666;
					text-transform: uppercase;
					margin-top: 5px;
					margin-bottom: 5px;
					font: {
						size: 13px;
						weight: 500;
					};
				}
				.title {
					margin-bottom: 0;
					line-height: 1.5;
					font: {
						size: 18px;
						weight: 700;
					};
					a {
						display: inline-block;
						color: #474c40;

						&:hover {
							color: $main-color;
						}
					}
				}
			}
		}
	}
	.widget_recent_entries {
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 12px;
				color: #666666;
				padding-left: 17px;
				line-height: 1.5;
				font: {
					size: 15px;
					weight: 600;
				};
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					background: $main-color;
					position: absolute;
					height: 7px;
					width: 7px;
					content: '';
					left: 0;
					top: 7px;
				}
				.post-date {
					display: block;
					font-size: 13px;
					color: #666666;
					margin-top: 4px;
				}
			}
		}
	}
	.widget_categories {
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				position: relative;
				padding-bottom: 10px;
				margin-bottom: 10px;
				color: #666666;
				padding-left: 17px;
				border-bottom: 1px solid #efefef;
				font: {
					size: 15px;
					weight: 600;
				};
				&:last-child {
					margin-bottom: 0;
					border-bottom: none;
				}
				&::before {
					background: $main-color;
					position: absolute;
					height: 10px;
					width: 10px;
					content: '';
					left: 0;
					top: 8px;
					border-radius: 50%;;
				}
				a {
					display: block;
					color: #666666;
					font: {
						size: 14px;
					};
					span {
						float: right;
						font-size: 13px;
					}
					&:hover {
						color: $main-color;
					}
				}
				.post-count {
					float: right;
				}
			}
		}
	}
	.widget_meta {
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			
			li {
				position: relative;
				margin-bottom: 12px;
				color: #666666;
				padding-left: 17px;
				font: {
					size: 15px;
					weight: 600;
				};
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					background: $main-color;
					position: absolute;
					height: 7px;
					width: 7px;
					content: '';
					left: 0;
					top: 7px;
				}
			}
		}
	}
	.widget_tag_cloud {
		.widget-title {
			margin-bottom: 0;
		}
	}
	.tagcloud {
		a {
			display: inline-block;
			color: #666666;
			padding: 7px 10px;
			border: 1px dashed #eeeeee;
			font: {
				weight: 600;
				size: 14px !important;
			};
			margin: {
				top: 10px;
				right: 10px;
			};
			&:hover {
				background-color: $main-color;
				color: $white-color;
			}
			&:focus {
				background-color: $main-color;
				color: $white-color;
				border-color: $main-color;
			}
		}
	}
	.widget_services_list {
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 0;
			box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
			background-color: $white-color;

			li {
				a {
					display: block;
					position: relative;
					color: #5d6576;
					z-index: 1;
					font-weight: 500;
					border-bottom: 1px solid #eeeeee;
					padding: {
						top: 15px;
						bottom: 15px;
						left: 15px;
						right: 35px;
					};
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 0;
						height: 100%;
						background: #252920;
						transition: 0.5s;
						opacity: 0;
						visibility: hidden;
					}
					i {
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover {
						background-color: $main-color;
						color: $white-color;
						padding-left: 20px;

						&::before {
							opacity: 1;
							visibility: visible;
							width: 5px;
						}
					}
				}
				a.active {
					background-color: $main-color;
					color: $white-color;
					padding-left: 20px;

					&::before {
						opacity: 1;
						visibility: visible;
						width: 5px;
					}
				}
				&:last-child {
					a {
						border-bottom: none;
					}
				}
			}
		}
	}
	.widget_download {
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 0;
			box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
			background-color: $white-color;

			li {
				a {
					display: block;
					position: relative;
					color: #5d6576;
					z-index: 1;
					font-weight: 500;
					border-bottom: 1px solid #eeeeee;
					padding: {
						top: 15px;
						bottom: 15px;
						left: 15px;
						right: 35px;
					};
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 0;
						height: 100%;
						background: #252920;
						transition: 0.5s;
						opacity: 0;
						visibility: hidden;
					}
					i {
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover {
						background-color: $main-color;
						color: $white-color;
						padding-left: 20px;

						&::before {
							opacity: 1;
							visibility: visible;
							width: 5px;
						}
					}
				}
				a.active {
					background-color: $main-color;
					color: $white-color;
					padding-left: 20px;

					&::before {
						opacity: 1;
						visibility: visible;
						width: 5px;
					}
				}
				&:last-child {
					a {
						border-bottom: none;
					}
				}
			}
		}
	}
	.info time {
		font-size: 13px;
		color: #929292;
		display: block;
		margin-bottom: 4px;
	}
}

/*
Contact Info Area Style 
================================*/
.contact-info-area {
	.single-contact-info {
		text-align: center;
		box-shadow: $box-shadow;
		background-color: $white-color;
		margin-bottom: 30px;
		padding: 30px;
		transition: all 0.5s;
		border-radius: $border-radius;

		i {
			display: block;
			color: $main-color;
			transition: all 0.5s;
			font-size: 25px;
			width: 50px;
			height: 50px;
			margin: 0 auto 25px;
			line-height: 50px;
			border: 2px dotted $main-color;
			border-radius: 50%;
		}
		h3 {
			transition: all 0.5s;
			font-size: 24px;
			margin-bottom: 10px;
		}
		a {
			color: $body-color;
			transition: all 0.5s;
			display: block;
		}
		&:hover {
			background-color: $main-color;

			i {
				color: $white-color;
				border-color: $white-color;
			}
			h3 {
				color: $white-color;
			}
			a {
				color: $white-color;
			}
		}
	}
}
#map {
    iframe {
		height: 480px;
		width: 100%;
		border: none;
	}
}

/*
Contact Area Style
=====================================================*/
.main-contact-area { 
	.contact-wrap {
		max-width: unset;

		.section-title {
			text-align: center !important;
			max-width: 600px;

			h2 {
				margin-bottom: 10px;
			}
		}
		.form-group {
			margin-bottom: 30px;
		}
		.default-btn {
			margin: auto;
			display: table;
			cursor: pointer;
		}
		.contact-form {
			background-color: $white-color;
			box-shadow: $box-shadow;
			padding: 50px;
			border-radius: $border-radius; 
		}
		.form-control {
			border-radius: $border-radius !important; 
		}
	}
}

/*
Team Expert Area Two Style
======================================================*/
.expert-team-area {
	.single-team {
		position: relative;
		overflow: hidden;
		margin-bottom: 30px;

		&:before {
			content: "";
			position: absolute;
			left: 170%;
			z-index: 1;
			height: 100%;
			width: 100%;
			background: rgba(255,255,255,0.2);
			transform: skewX(45deg);
			transition: .8s;
		}
		img {
			width: 100%;
			height: 100%;
			filter: grayscale(100%);
			transition: 1s;
		}
		.team-content {
			position: absolute;
			background: $heading-color;
			width: 100%;
			bottom: 0;
			transition: $transition;
			transform: perspective(300px)rotateX(90deg);
			transform-origin: bottom;
			text-align: center;
			padding: 10px;

			h3 {
				margin-bottom: 10px;
				color: $white-color;
				font-size: 24px;
			}
			span {
				color: $white-color;
			}
		}
		ul {
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				background: $heading-color;
				width: 40px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				border-bottom: 1px solid #19345a;
				transform: perspective(1000px) rotateY(90deg);
				transition: $transition;
				transform-origin: left;
				
				&:hover {
					background-color: $main-color;
				}
				a {
					color: $white-color;
				}
				&:nth-child(1) {
					transition-delay: .2s;
				}
				&:nth-child(2) {
					transition-delay: .4s;
				}
				&:nth-child(3) {
					transition-delay: .6s;
				}
				&:nth-child(4) {
					transition-delay: .8s;
				}
			}
		}
		&:hover {
			&:before {
				left: -170%;
				transition-delay: .8s;
			}
			img {
				filter: grayscale(0);
				transform: scale(1.2);
			}
			.team-content {
				transform: perspective(300px)rotateX(0deg);
			}
			ul {
				li {
					transform: rotateX(0deg);
				}
			}
		}
	}
}