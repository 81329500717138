/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-right:before { content: "\f100"; }
.flaticon-left:before { content: "\f101"; }
.flaticon-check:before { content: "\f102"; }
.flaticon-tick:before { content: "\f103"; }
.flaticon-male:before { content: "\f104"; }
.flaticon-speak:before { content: "\f105"; }
.flaticon-settings:before { content: "\f106"; }
.flaticon-password:before { content: "\f107"; }
.flaticon-backup:before { content: "\f108"; }
.flaticon-speedometer:before { content: "\f109"; }
.flaticon-speedometer-1:before { content: "\f10a"; }
.flaticon-network:before { content: "\f10b"; }
.flaticon-molecule:before { content: "\f10c"; }
.flaticon-diagram:before { content: "\f10d"; }
.flaticon-migrating:before { content: "\f10e"; }
.flaticon-management:before { content: "\f10f"; }
.flaticon-verified:before { content: "\f110"; }
.flaticon-network-1:before { content: "\f111"; }
.flaticon-database:before { content: "\f112"; }
.flaticon-calendar:before { content: "\f113"; }
.flaticon-appointment:before { content: "\f114"; }
.flaticon-database-1:before { content: "\f115"; }
.flaticon-uptime:before { content: "\f116"; }
.flaticon-customer-service:before { content: "\f117"; }